/* @import "~bootstrap/dist/css/bootstrap.css"; */
@import "~ngx-toastr/toastr";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", "neue helvetica", sans-serif;
}

.no-Padding {
  padding: 0px;
}

.body-gutter-spacing {
  padding: 0px 20px;
}
html {
  overflow-y: overlay;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.p-radiobutton {
  margin-bottom: 4px !important;
}
.col-form-label {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.form-field label {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.p-component-overlay {
  background-color: rgb(0 0 0 / 80%) !important;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-corner {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 10px;
}

.img-logo {
  width: 153px !important;
}
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.p-dropdown-item {
  font-weight: 400;

  font-size: 14px;
}
.p-multiselect-item {
  font-weight: 400;

  font-size: 14px;
}
:not(.p-highlight) > .p-tabview-nav-link {
  border: 1px solid white !important;
  border-color: white !important;
  background: white !important;
}
.p-highlight > .p-tabview-nav-link {
  background: grey !important;
  border-color: gray !important;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #ffffff00 !important;
}
.form-control:focus {
  border-color: black !important;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
}
.p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: inset 0 0 0 0.2rem #eceff100 !important;
}
.p-tabview .p-tabview-panels {
  border: 0px;
  border-top: 2px solid #808080 !important;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  box-shadow: 0 0 0 0.2rem #ffffff00;
}
.p-fieldset {
  margin-bottom: 8px;
}
.p-fieldset .p-fieldset-legend {
  background: #dbdbdb !important;
  border-color: #dbdbdb !important;
}
.p-multiselect-filter {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: #eaeaea !important;
  color: black;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: inset 0 0 0 0.15rem #8dcdff00 !important;
}

.cust-body {
  background-image: url(../src/assets/images/BG.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 4px 1rem !important;
}

.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background: #d3d8db !important;
  border-color: #9d9696 !important;
  color: #ffffff !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.form-label label > sup {
  font-size: 10px;
  font-weight: 900;
  padding-left: 4px;
}
.form-label label:not(+ sup) {
  padding-right: 4px;
}

.form-field {
  padding: 0px !important;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #707070;
}
.p-paginator-bottom button {
  height: 30px !important;
  min-width: 30px !important;
}
.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.resetFormStyles {
  margin-top: 30px;
  margin-left: 0px;
  margin-bottom: 88px;
}

.resetFormStyles > .form-label > span {
  font-size: 14px;
}
.resetFormStyles h4 {
  font-size: 24px;
}
.resetFormStyles .row {
  padding-bottom: 20px;
}
@media (min-width: 1000px) {
  .container {
    min-width: 920px;
  }
}
@media (min-width: 1100px) {
  .container {
    min-width: 1020px;
  }
}
@media (min-width: 1200px) {
  .container {
    min-width: 1120px;
  }
}
@media (min-width: 1300px) {
  .container {
    min-width: 1220px;
  }
}
@media (min-width: 1400px) {
  .container {
    min-width: 1320px;
  }
}
@media (min-width: 1500px) {
  .container {
    width: 1420px;
  }
}
@media (min-width: 1600px) {
  .container {
    min-width: 1520px;
  }
}
@media (min-width: 1700px) {
  .container {
    min-width: 1620px;
  }
}

@media (min-width: 1800px) {
  .container {
    min-width: 1720px;
  }
}
@media (min-width: 1900px) {
  .container {
    min-width: 1820px;
  }
}
/* ========================================prime ng============================================ */
.p-button.cancel:enabled:hover {
  background: gray;
  color: #ffffff;
  border-color: #0c0c0c00;
}
.p-button.accept:enabled:hover {
  background: #004890;
  color: #ffffff;
  border-color: #0c0c0c00;
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #0c0c0c00;
}
.p-button {
  border-color: #0c0c0c00;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #0c0c0c00;
  border: 1px solid #a6a6a6;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #0c0c0c00;
  border: 1px solid #a6a6a6;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border: 1px solid #a6a6a6;
  background: gray;
}
/* .p-checkbox-focused{
  box-shadow: 0 0 0 0.2rem #0c0c0c00 !important;
} */
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border: 1px solid #a6a6a6;
  background: gray;
  color: #ffffff;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #0c0c0c00;
  border: 1px solid #a6a6a6;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem #0c0c0c00;
  border: 1px solid #a6a6a6;
}
.p-tabview-title {
  font-weight: 300 !important;
}
.p-highlight .p-tabview-title {
  font-weight: 700 !important;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border: 1px solid #a6a6a6;
  background: rgba(128, 128, 128, 0);
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border: 1px solid #a6a6a6;
  background: rgba(128, 128, 128, 0);
  color: #ffffff;
}
.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  transform: translateZ(0) scale(1, 1);
  visibility: visible;
  background: gray;
}

.p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #0c0c0c00;
}
.p-accordion-header-link {
  color: #676767 !important;
}
.p-dropdown-label {
  font-size: 14px;
  padding: 0px;
  margin: 3px 4px 0px;
}
.p-multiselect-label {
  font-size: 14px;
  padding: 0px;
  margin: -4px -3px 0px;
}
.p-checkbox-box {
  width: 16px !important;
  height: 16px !important;
  margin-top: 2px !important;
}
.form-control {
  padding: 0px 8px !important;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-top: 5px !important;
}
.p-paginator .p-dropdown {
  height: 2rem;
}
.row {
  align-items: center;
}
.container {
  min-width: 100% !important;
}

.p-link:focus {
  box-shadow: 0 0 0 0.2rem #8dceff00 !important;
}
.navbar-light .navbar-toggler {
  border-color: rgb(0 0 0 / 0%) !important;
}
@media only screen and (min-width: 1px) and (max-width: 479px) {
  .header-navbar-ul > li:hover > a {
    color: white !important;
  }

  .home-bg {
    background-image: url(../src/assets/images/bg-350px.jpg) !important;
  }
  hr {
    border: 1px solid gray !important;
    padding: 0px !important;
    margin: 0px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    opacity: 100% !important;
  }
  .header-navbar > ul li + li {
    margin-left: 0px !important;
  }
  .header .w-25,
  .header .w-75 {
    width: 100% !important;
  }

  .header-logo-text {
    padding-right: 2px !important;
  }
  .header-navbar {
    background-color: rgb(184, 179, 179) !important;
    padding: 0px;
    padding-right: 0px !important;

    z-index: 1 !important;
  }
  .navbar-toggle {
    border: 0px !important;
    border-color: rgb(0 0 0 / 0%) !important;
  }
  .header-navbar > ul {
    align-items: flex-end !important;
  }
  .header-navbar-ul {
    padding: 0px !important;
  }
  .navbar-toggler:focus {
    box-shadow: 0 0 0 0rem !important;
  }
  .nav-item {
    width: 100% !important;
    padding: 12px 0px 12px 0px !important;
    text-align: right !important;
  }
  .nav-item:hover {
    background-color: gray !important;
  }
  #mobile-home-image {
    display: block !important;
  }
  #mobile-not-home-image {
    display: none !important;
  }
  .nav-item li:hover {
    background-color: gray !important;
    color: white !important;
  }
  .active {
    background-color: transparent !important;
    color: black !important;
  }
  .active-img {
    background-color: transparent !important;
  }
  .form {
    padding-right: 0px !important;
  }
  .navbar-collapse {
    position: absolute;
    right: 0px;
    top: 80px;
    /* height: 100vh; */
    width: 51%;
    padding: 0px !important;
  }
  .header-navbar-ul > ul {
    align-items: flex-start;
  }
  li + li {
    margin-left: 0px !important;
  }
  .navbar-toggler {
    margin-right: 3px !important;
  }

  .btn-group-custom {
    font-size: 10px !important;
    display: block !important;
    float: left !important;
    width: 100% !important;
  }

  .btn-group {
    display: inline !important;
  }
  .home-button-grp span {
    display: none !important;
  }
  .home-button-grp br {
    display: none !important;
  }

  .resetFormStyles {
    margin-top: 92px !important;
  }
  .form-label {
    font-size: 8px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .header-logo-text {
    padding-right: 106px !important;
  }
  .home-bg {
    background-image: url(../src/assets/images/bg-480px.jpg) !important;
  }
  .header .w-25,
  .header .w-75 {
    width: 100% !important;
  }
  .header-navbar-ul > li:hover > a {
    color: white !important;
  }
  hr {
    border: 1px solid gray !important;
    padding: 0px !important;
    margin: 0px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    opacity: 100% !important;
  }
  #mobile-home-image {
    display: block !important;
  }
  #mobile-not-home-image {
    display: none !important;
  }

  .header-navbar {
    background-color: rgb(184, 179, 179) !important;
    padding: 0px;
    padding-right: 0px !important;
    z-index: 1 !important;
  }
  .navbar-toggle {
    border: 0px !important;
    border-color: rgb(0 0 0 / 0%) !important;
  }
  .header-navbar > ul {
    align-items: flex-end !important;
  }
  .header-navbar-ul {
    padding: 0px !important;
  }
  .navbar-toggler:focus {
    box-shadow: 0 0 0 0rem !important;
  }
  .nav-item {
    width: 100% !important;
    padding: 12px 0px 12px 0px !important;
    text-align: right !important;
  }
  .nav-item:hover {
    background-color: gray !important;
  }

  .nav-item li:hover {
    background-color: gray !important;
    color: white !important;
  }
  .active {
    background-color: transparent !important;
    color: black !important;
  }
  .active-img {
    background-color: transparent !important;
  }
  .form {
    padding-right: 0px !important;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0.557rem 1rem !important;
  }
  .navbar-collapse {
    position: absolute;
    right: 0px;
    top: 80px;
    /* height: 100vh; */
    width: 51%;
    padding: 0px !important;
  }
  .header-navbar-ul > ul {
    align-items: flex-start;
  }
  li + li {
    margin-left: 0px !important;
  }
  .navbar-toggler {
    margin-right: 3px !important;
  }

  .btn-group-custom {
    font-size: 10px !important;
    display: block !important;
    float: left !important;
    width: 100% !important;
  }

  .btn-group {
    display: inline !important;
  }
  .home-button-grp span {
    display: none !important;
  }
  .home-button-grp br {
    display: none !important;
  }

  .resetFormStyles {
    margin-top: 92px !important;
  }
  .form-label {
    font-size: 8px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-bg {
    background-image: url(../src/assets/images/bg-767px.jpg) !important;
  }
  .header-navbar-ul > li:hover > a {
    color: white !important;
  }
  hr {
    border: 1px solid gray !important;
    padding: 0px !important;
    margin: 0px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    opacity: 100% !important;
  }
  .header .w-25,
  .header .w-75 {
    width: 100% !important;
  }
  #mobile-home-image {
    display: block !important;
  }
  #mobile-not-home-image {
    display: none !important;
  }
  .resetFormStyles {
    margin-top: 92px !important;
  }
  .header-logo-text {
    padding-right: 394px !important;
  }
  .header-navbar {
    background-color: rgb(184, 179, 179) !important;
    padding: 0px;
    padding-right: 0px !important;
    z-index: 1 !important;
  }
  .navbar-toggle {
    border: 0px !important;
    border-color: rgb(0 0 0 / 0%) !important;
  }
  .header-navbar > ul {
    align-items: flex-end !important;
  }
  .header-navbar-ul {
    padding: 0px !important;
  }
  .navbar-toggler:focus {
    box-shadow: 0 0 0 0rem !important;
  }
  .nav-item {
    width: 100% !important;
    padding: 12px 0px 12px 0px !important;
    text-align: right !important;
  }
  .nav-item:hover {
    background-color: gray !important;
  }

  .nav-item li:hover {
    background-color: gray !important;
    color: white !important;
  }
  .active {
    background-color: transparent !important;
    color: black !important;
  }
  .active-img {
    background-color: transparent !important;
  }
  .form {
    padding-right: 0px !important;
  }
  .navbar-collapse {
    position: absolute;
    right: 0px;
    top: 80px;
    /* height: 100vh; */
    width: 51%;
    padding: 0px !important;
  }
  .header-navbar-ul > ul {
    align-items: flex-start;
  }
  li + li {
    margin-left: 0px !important;
  }
  .navbar-toggler {
    margin-right: 3px !important;
  }

  .btn-group-custom {
    font-size: 10px !important;
    display: block !important;
    float: left !important;
    width: 100% !important;
  }

  .btn-group {
    display: inline !important;
  }
  .home-button-grp span {
    display: none !important;
  }
  .home-button-grp br {
    display: none !important;
  }

  .form-label {
    font-size: 8px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #mobile-home-image {
    display: none !important;
  }
  #mobile-not-home-image {
    display: block !important;
  }
  .header .w-25,
  .header .w-75 {
    width: 100% !important;
  }

  .resetFormStyles {
    margin-top: 92px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 3000px) {
  #mobile-home-image {
    display: none !important;
  }
  #mobile-not-home-image {
    display: block !important;
  }
}
